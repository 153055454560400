"use client"

import { Analytics as VercelAnalytics } from "@vercel/analytics/react"
import { useEffect } from "react"
import * as koala from '@/lib/koala'

export function Analytics() {
  useEffect(() => {
    koala.init()
  }, [])
  return <VercelAnalytics />
}