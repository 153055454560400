import type { User } from '@api/types'

export function init() {
  ;(function () {
    if ((window as any).ko) return
    ;((window as any).ko = []),
      ['identify', 'track', 'removeListeners', 'open', 'on', 'off', 'qualify', 'ready'].forEach(
        function (t) {
          ;((window as any).ko as any)[t] = function (...args: any[]) {
            const n = Array.prototype.slice.call(args)
            return n.unshift(t), ((window as any).ko as any[]).push(n), (window as any).ko
          }
        },
      )
    const n = document.createElement('script')
    ;(n.async = true),
      n.setAttribute(
        'src',
        'https://cdn.getkoala.com/v1/pk_5684a950957d69372ba3d423d2fa66944f8a/sdk.js',
      ),
      (document.body || document.head).appendChild(n)
  })()
}

export function identify(user: User) {
  const ko = (window as any).ko
  if (!ko) {
    return
  }
  try {
    ko.identify({
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    })
  } catch (err) {
    console.error('[koala] identify:', err)
  }
}

export function track(eventName: string, props?: Record<string, any>) {
  const ko = (window as any).ko
  if (!ko) {
    return
  }
  ko.track(eventName, props)
}

export function reset() {
  const ko = (window as any).ko
  if (!ko) {
    return
  }
  ko.reset()
}
